<script>
var moment = require("moment");
moment.locale("fr");
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import Swal from "sweetalert2";
import loaderProcess from "../../../components/widgets/loaderProcess.vue";
import { Api } from "../../../helpers";
import { Erreur } from "../../../helpers/error";
/**
 * Projects-list component
 */
export default {
  page: {
    title: "Projects List",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    loaderProcess,
  },

  data() {
    return {
      process: false,
      modalOk: false,
      gestInfos: {
        titre: "",
        description: "",
      },
      imageLot: [],
      images: [],
      gestScreens: [],
      moment: moment,
      title: "Liste des Images",
      items: [
        {
          text: "Outils",
          // href: "/projects/images",
        },
        {
          text: "Images",
          active: true,
        },
      ],
    };
  },
  methods: {
    showModal() {
      this.modalOk = true;
    },

    imageCategorie(event) {
      this.imgSelect = event.target.files[0];
      if (this.imgSelect) {
        const imageURL = URL.createObjectURL(this.imgSelect);
        this.imageLot.push(imageURL);
      }
    },

    createImageLot() {
      // Envoie une requête à une API avec les fichiers, la description et le titre
      Api.postFormData("/streamvod/rest/invite/create-guest-screen", {
        files: this.imageLot,
        description: this.gestInfos.description,
        titre: this.gestInfos.titre,
      })
        .then(() => {
          // Cache la fenêtre modale de chargement
          this.process = false;

          // Affiche un message de succès à l'utilisateur
          Swal.fire("Succès !", "Block d'image ajouté", "success");


          // Recharge la page
          location.reload();
        })
        .catch((error) => {
          // Cache la fenêtre modale de chargement
          this.process = false;

          // Gère l'erreur et affiche un message à l'utilisateur
          Erreur.gestionErreur(error.message);
        });
    },
  },
  mounted() {
    Api.get("/streamvod/api/invite/guest-screen/all")
      .then((res) => {
        this.gestScreens = res.data.content;
      })
      .catch((error) => {
        this.process = false;
        Erreur.gestionErreur(error.message);
      });
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row justify-content-end">
      <div class="col-lg-10" style="margin-top: 10px">
        <button
          type="submit"
          class="btn c2play-primary"
          style="position: relative; left: 90%"
          @click="showModal"
        >
          {{ $t("addButton") }}
        </button>
      </div>
    </div>
    <br />

    <div class="row">
      <div class="col-lg-12">
        <div
          v-for="gestScreen in gestScreens"
          :key="gestScreen.id"
          class="card pt-4"
          style="padding-left: 5%; padding-right: 5%"
        >
          <div class="row">
            <h2 class="col-lg-11">{{ gestScreen.titre }}</h2>
            <br />

            <div>
              {{ gestScreen.description }}
            </div>
            <br />
            <!-- <div class="col-lg-1">
                    <p class="card-title-desc">
                            <b-dropdown
                                    class="card-drop"
                                    variant="white"
                                    menu-class="dropdown-menu-end"
                                    right
                                    toggle-class="p-0"
                                    >
                                    <template v-slot:button-content>
                                        <i class="mdi mdi-dots-horizontal font-size-18"></i>
                                    </template>
                                    <b-dropdown-item :href="'/projects/overview/' + section.id"
                                        >Détails</b-dropdown-item
                                    >
                                    <b-dropdown-item :href="'/projects/updateVideo/' + section.id"
                                        >Modifier</b-dropdown-item
                                    >
                                    <b-dropdown-item @click="confirm(section.id)"
                                        >Supprimer</b-dropdown-item
                                    >
                                    </b-dropdown>
                            </p>
                </div> -->
          </div>

          <div class="display">
            <div
              class="col-lg-3 card-img"
              v-for="image in gestScreen.images.slice().reverse()"
              :key="image.id"
            >
              <div class="card">
                <div class="card-body">
                  <div class="ratio ratio-4x3">
                    <img
                      v-if="image.url"
                      :src="image.url"
                      class="img-fluid mx-auto d-block tab-img rounded"
                    />
                    <img
                      v-if="!image.url"
                      src="@/assets/logos/galerie.png"
                      class="img-fluid mx-auto d-block tab-img rounded"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->

    <!-- <div class="row">
      <div class="col-12">
        <div class="text-center my-3">
          <a href="javascript:void(0);" class="text-success">
            <i class="bx bx-loader bx-spin font-size-18 align-middle me-2"></i>
            Load more
          </a>
        </div>
      </div>
    </div> -->

    <b-modal
      v-model="modalOk"
      id="modal-ml"
      size="ml"
      :title="$t('pages.image.formulaire.title')"
      title-class="font-18"
      hide-footer
    >
      <div class="card-body">
        <form>
          <div class="form-group row mb-4">
            <label for="projectname" class="col-form-label">{{
              $t("pages.image.formulaire.titre.label")
            }}</label>
            <div class="col-lg-12">
              <input
                id="projectname"
                name="projectname"
                type="text"
                class="form-control"
                v-model="gestInfos.titre"
                :placeholder="$t('pages.image.formulaire.titre.placeholder')"
              />
            </div>
          </div>

          <div class="form-group row mb-4">
            <label for="projectbudget" class="col-form-label">{{
              $t("pages.image.formulaire.image")
            }}</label>
            <div class="col-lg-12">
              <input
                id="projectbudget"
                name="projectbudget"
                type="file"
                @change="imageCategorie($event)"
                class="form-control"
                accept=".png, .jpeg, .jpg"
              />
            </div>
          </div>
          <div>
            <img :src="imageURL" alt="Image sélectionnée" v-if="imageURL" />
          </div>
          <div v-if="imageLot.length > 0">
            <div class="row">
              <div v-for="(image, index) in imageLot" :key="index">
                <div class="col-xl-4">
                  <img
                    :src="image"
                    alt="Image"
                    style="height: 100px; width: 100px"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="form-group row">
            <label for="projectdesc" class="col-form-label">{{
              $t("pages.image.formulaire.description.label")
            }}</label>
            <div class="col-lg-12">
              <textarea
                id="projectdesc"
                class="form-control"
                rows="3"
                v-model="gestInfos.description"
                :placeholder="
                  $t('pages.image.formulaire.description.placeholder')
                "
              ></textarea>
            </div>
          </div>
        </form>
        <!-- <div class="row mb-4">
              <label class="col-form-label col-lg-2">Vidéo</label>
              <div class="col-lg-10">
                <DropZone @drop.prevent="drop" @change="selectedFile" />
                <span class="file-info">Fichier: {{ dropzoneFile.name }}</span>
              </div>
            </div> -->
        <!-- <h4>{{ tags }}</h4>
            <h4>{{ categories }}</h4> -->
        <div class="row justify-content-end">
          <div class="col-lg-10" style="margin-top: 10px">
            <button
              type="submit"
              class="btn c2play-primary"
              style="position: relative; left: 30%"
              @click="createImageLot"
            >
              {{ $t("addButton") }}
            </button>
          </div>
        </div>
      </div>
    </b-modal>
    <loaderProcess v-if="process == true"></loaderProcess>
    <!-- end row -->
  </Layout>
</template>

<style>
.card-img {
  object-fit: cover;
  height: 250px;
  width: 30%;
  border-radius: 9px;
  margin-bottom: 20px;
}
</style>